<template>
  <div v-if="stripe_integration && $can('manage payment settings')">
    <h5 class="fw-bold text-primary">Stripe Payments</h5>
    <div v-if="user.email.includes('@swandoola.com')">
      <p class="alert alert-danger">
        <i class="far fa-exclamation-circle" />
        Stripe Payments are being discontinued in favour of Swandoola Payments. Please get started with Swandoola Payments as soon as possible to ensure a seamless transition.
      </p>
    </div>

    <div class="card bg-light shadow-sm">
      <div class="card-body">
        <verification :stripe_integration="stripe_integration" @updated="fetchStripeIntegration" />
        <bank-accounts v-if="stripe_integration.stripe_connect_id" />
      </div>
    </div>
  </div>
</template>

<script>
import Verification from "./stripe-connect-partials/Verification";
import BankAccounts from "./stripe-connect-partials/BankAccounts";

export default {
  props: [],
  data() {
    return {
      stripe_integration: null
    };
  },
  methods: {
    fetchStripeIntegration(){
        this.$axios.get(process.env.VUE_APP_API_URL + '/settings/payments/fetch-stripe-integration', {})
            .then(({data}) => {
                this.stripe_integration = data;
            });
    },
    sendAlert(msg) {
      if (msg.length > 50) {
        var multi = true;
      } else {
        var multi = false;
      }
      this.$EventBus.$emit("alert", {
        message: msg,
        actionText: null,
        actionHandler: null,
        timeout: 5000,
        multiline: multi
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    }
  },
  mounted() {
    this.fetchStripeIntegration();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    }
  },
  components: {
      BankAccounts,
      Verification,
  }
};
</script>

<style>
</style>
