<template>
  <div class="mb-3">
    <div class="card bg-light" v-if="adyenIntegration.integration_details">
      <div class="card-body">
        <div class="mt-3">
          <p class="alert alert-primary">
            Configure the frequency which payouts are sent to your bank account.
          </p>
          <div class="row">
            <div class="col-4">
              <select class="form-control" v-model="schedule">
                <option :value="null" selected disabled>Select payout frequency</option>
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
              </select>
            </div>
            <div class="col">
              <button class="btn btn-primary"
                      @click="updateSchedule"
                      :disabled="saving || !schedule || schedule === adyenIntegration.payout_schedule">
                <i class="far me-1" :class="saving ? 'fa-spin fa-spinner' : 'fa-save'"></i>
                Save
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["adyenIntegration"],
  data() {
    return {
      schedule: this.adyenIntegration.payout_schedule,
      saving: false
    };
  },
  methods: {
    updateSchedule() {
      this.saving = true
      this.$axios.post(process.env.VUE_APP_API_URL + "/settings/payments/adyen/payout-schedule", {
        payout_schedule: this.schedule
      }).then(({ data }) => {
        this.$EventBus.$emit("refreshUser");
        this.$EventBus.$emit("alert", data);
        this.saving = false;
      });
    }
  },
  watch: {

  },
  computed: {

  },
  mounted() {
  },
  filters: {
  },
  components: {
  }
};
</script>
