<template>
  <div>
    <p v-if="!adyenIntegration.processing_disabled"
       class="badge bg-success p-2 me-2 my-0">
      <i class="far fa-check-circle me-1" />
      Accept Card Payments
    </p>
    <p v-else
       class="badge bg-danger p-2 me-2 my-0">
      <i class="far fa-times-circle me-1" />
      Accept Card Payments
    </p>

    <p v-if="adyenIntegration.payouts_allowed"
       class="badge bg-success p-2 my-0">
      <i class="far fa-check-circle me-1" />
      Payout to Bank Account
    </p>
    <p v-else
       class="badge bg-danger p-2 my-0">
      <i class="far fa-times-circle me-1" />
      Payout to Bank Account
    </p>
  </div>
</template>

<script>

export default {
  props: ["adyenIntegration"],
  data() {
    return {
    };
  },
  methods: {
  },
  watch: {

  },
  computed: {

  },
  mounted() {
  },
  filters: {
  },
  components: {
  }
};
</script>
