<template>
  <div class="mb-3">
    <div class="card bg-light" v-if="adyenIntegration.integration_details">
      <div class="card-body">
        <div class="mt-3">
          <p class="alert alert-primary">
            In order to take payments and receive payouts via Swandoola, you will need to provide Know Your Customer (KYC) details in order verify your identity with our payment processor.
          </p>
          <div class="text-center">
            <button class="btn btn-primary" @click="getOnboardingUrl">Update your KYC information</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ["adyenIntegration"],
  methods: {
    getOnboardingUrl() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/payments/adyen/onboarding-url").then(({data}) => {
        window.location = data.redirectUrl;
      });
    }
  },
  watch: {

  },
  computed: {

  },
  mounted() {
  },
  filters: {
  },
  components: {
  }
};
</script>
