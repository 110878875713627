<template>
  <div>
    <div class="row text-center my-5" v-if="busy">
      <div class="col">
        <i class="far fa-spinner fa-5x fa-spin text-primary"></i>
      </div>
    </div>
    <div class="row mb-3" v-if="!busy && bankAccounts">
      <div class="col">
        <h4 class="mb-3">
                  Bank Accounts (Stripe)
                  <button
                    class="btn btn-outline-primary btn-sm float-end"
                    v-if="!showAdd"
                    @click="showAdd = true"
                  >
                    <i class="far fa-plus me-1"></i>
                    Add Bank Account
                  </button>
        </h4>
        <div class="card" v-if="!showAdd">
          <table
            class="table table-hover mb-0"
            v-if="bankAccounts.length > 0"
          >
            <thead>
              <tr>
                <th scope="col" style="border-top: none">Bank</th>
                <th
                  class="text-center"
                  scope="col"
                  style="border-top: none"
                >
                  Country
                </th>
                <th
                  class="text-center"
                  scope="col"
                  style="border-top: none"
                >
                  Currency
                </th>
                <th
                  class="text-center"
                  scope="col"
                  style="border-top: none"
                >
                  Account
                </th>
                <th
                  class="text-center"
                  scope="col"
                  style="border-top: none"
                >
                  Sort Code
                </th>
                <th
                  class="text-center"
                  scope="col"
                  style="border-top: none"
                >
                  Default
                </th>
                <th
                  class="text-center"
                  scope="col"
                  style="border-top: none"
                >
                  <i class="far fa-trash"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="account in bankAccounts">
                <td>{{ account.bank_name }}</td>
                <td class="text-center">{{ account.country }}</td>
                <td class="text-center text-uppercase">
                  {{ account.currency }}
                </td>
                <td class="text-center">***{{ account.last4 }}</td>
                <td class="text-center">
                  {{ account.routing_number }}
                </td>
                <td class="text-center small">
                  <i
                    class="far fa-2x fa-check-circle text-primary"
                    v-if="account.default_for_currency"
                  ></i>
                  <i
                    class="far fa-2x fa-times-circle cursor-pointer"
                    @click="setDefault(account)"
                    v-if="!account.default_for_currency"
                  ></i>
                </td>
                <td class="text-center">
                  <i
                    class="far fa-trash cursor-pointer"
                    @click="deleteAccount(account)"
                    v-if="!account.default_for_currency"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="card-body">
            <div class="row">
              <div class="col text-center">
                <p>
                  <i class="far fa-info-circle fa-3x text-primary"></i>
                </p>
                <p>No bank accounts found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-else>
          <div class="card-body">
            <form @submit.prevent="addBankAccount" method="post">
              <div class="row mb-3">
                <div class="col">
                  <label for="bank-name">Bank Name *</label>
                  <input
                    type="text"
                    id="bank-name"
                    v-model="bankName"
                    placeholder="Bank Name*"
                    class="form-control"
                    required
                  />
                  <span
                    class="invalid-feedback"
                    style="display: none"
                  ></span>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <label for="bank-sort-code">Sort Code *</label>
                  <input
                    type="text"
                    id="bank-sort-code"
                    v-model="sortCode"
                    placeholder="Sort Code (no spaces / no dashes)*"
                    maxlength="6"
                    class="form-control"
                    required
                  />
                  <span
                    class="invalid-feedback"
                    style="display: none"
                  ></span>
                </div>
                <div class="col">
                  <label for="bank-account-number"
                    >Account Number *</label
                  >
                  <input
                    type="text"
                    id="bank-account-number"
                    v-model="accountNumber"
                    placeholder="Account Number*"
                    maxlength="16"
                    class="form-control"
                    required
                  />
                  <span
                    class="invalid-feedback"
                    style="display: none"
                  ></span>
                </div>
              </div>

              <div class="row">
                <div class="col text-end">
                  <button
                    class="me-2 btn btn-light"
                    v-if="showAdd"
                    @click="cancelAdd"
                  >
                    Cancel
                  </button>

                  <input
                    type="submit"
                    value="Save Bank Account"
                    class="btn btn-primary"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["path", "user"],
  data() {
    return {
      busy: true,
      bankAccounts: null,
      showAdd: false,
      bankName: null,
      sortCode: null,
      accountNumber: null,
    };
  },
  methods: {
    fetchBankAccounts() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/settings/payments/fetch-stripe-bank-accounts",
          {}
        )
        .then(({ data }) => {
          this.bankAccounts = data.data;
          this.busy = false;
        });
    },
    addBankAccount() {
      this.busy = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/payments/update-bank-account",
          {
            bank_name: this.bankName,
            sort_code: this.sortCode,
            account_number: this.accountNumber,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchBankAccounts();
          this.cancelAdd();
          this.busy = false;
        })
        .catch((error) => {
          if (error.response.status == 500) {
            this.$EventBus.$emit("alert", error.response.data);
          }
          this.busy = false;
        });
    },
    cancelAdd() {
      this.showAdd = false;
      this.bankName = null;
      this.sortCode = null;
      this.accountNumber = null;
    },
    deleteAccount(account) {
      var confirmed = confirm(
        "Are you sure you wish to remove this bank account?"
      );
      if (confirmed) {
        this.busy = true;
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/settings/payments/delete-bank",
            {
              stripe_bank_account_id: account.id,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchBankAccounts();
            this.busy = false;
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$EventBus.$emit("alert", error.response.data);
            }
            this.busy = false;
          });
      }
    },
    setDefault(account) {
      var confirmed = confirm(
        "Are you sure you wish to change your default bank account? This could trigger a temporary delay in payouts."
      );
      if (confirmed) {
        this.busy = true;
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/settings/payments/set-default-bank",
            {
              stripe_bank_account_id: account.id,
            }
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchBankAccounts();
            this.busy = false;
          })
          .catch((error) => {
            if (error.response.status == 500) {
              this.$EventBus.$emit("alert", error.response.data);
            }
            this.busy = false;
          });
      }
    },
  },
  mounted() {
    this.fetchBankAccounts();
  },
  filters: {
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
};
</script>

<style>
</style>
