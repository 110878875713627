<template>
  <div class="container-fluid" v-if="$can('manage payment settings')">
    <h4 class="fw-bold text-primary">Payment Processing</h4>
    <div class="row" style="min-height: 88vh">
      <div class="col-md-3 spark-settings-tabs">
        <payments-nav></payments-nav>
      </div>

      <!-- Tab cards -->
      <div class="col-md-9 ps-0">
        <setup-swandoola-payments
            @toggleSetup="showStripeConnect=!showStripeConnect"/>
        <div v-if="showStripeConnect && isStripeUser">
          <hr />
          <setup-stripe-connect/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import PaymentsNav from "@/views/areas/payments/partials/PaymentsNav";
import SetupStripeConnect from "./SetupStripeConnect";
import SetupSwandoolaPayments from "./SetupSwandoolaPayments";

export default {
  props: [],
  data() {
    return {
      showStripeConnect: true
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    isStripeUser() {
      return (this.user.clinic.stripe_integration_id > 0)
    }
  },
  components: {
    SetupSwandoolaPayments,
    SetupStripeConnect,
    PaymentsNav,
  }
};
</script>


<style scoped>

</style>
