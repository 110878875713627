<template>
  <div class="row">
    <div class="col-md-4 mb-3">
      <div class="card">
        <div class="card-label small text-white ms-3"
             :class="currentTab === 'integrationDetails' ? 'bg-primary' : adyenIntegration.integration_details ? 'bg-success' : 'bg-danger'"
        >
          Step 1
        </div>
        <button class="card-body btn btn-light"
                @click="currentTab='integrationDetails'"
                :class="currentTab === 'integrationDetails' ? 'border-primary' : ''">
          <i class="far me-2 fa-3x d-block mb-2"
             :class="currentTab === 'integrationDetails' ? 'fa-ellipsis-h text-primary' : adyenIntegration.integration_details ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"
          />
          Provide Business Details
        </button>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="card">
        <div class="card-label small text-white ms-3"
             :class="currentTab === 'kyc' ? 'bg-primary' : adyenIntegration.payouts_allowed ? 'bg-success' : 'bg-danger'"
        >
          Step 2
        </div>
        <button class="card-body btn btn-light"
                @click="currentTab='kyc'"
                :disabled="!adyenIntegration || !adyenIntegration.account_code"
                :class="currentTab === 'kyc' ? 'border-primary' : ''">
          <i class="far me-2 fa-3x d-block mb-2"
             :class="currentTab === 'kyc' ? 'fa-ellipsis-h text-primary' : adyenIntegration.payouts_allowed ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"
          />
          Identity Verification
        </button>
      </div>
    </div>
    <div class="col-md-4 mb-3">
      <div class="card">
        <div class="card-label small text-white ms-3"
             :class="currentTab === 'payoutSchedule' ? 'bg-primary' : adyenIntegration.payout_schedule ? 'bg-success' : 'bg-danger'"
        >
          Step 3
        </div>
        <button class="card-body btn btn-light"
                @click="currentTab='payoutSchedule'"
                :disabled="!adyenIntegration || !adyenIntegration.account_code"
                :class="currentTab === 'payoutSchedule' ? 'border-primary' : ''">
          <i class="far me-2 fa-3x d-block mb-2"
             :class="currentTab === 'payoutSchedule' ? 'fa-ellipsis-h text-primary' : adyenIntegration.payout_schedule ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'"
          />
          Configure Payouts
        </button>
      </div>
    </div>
</div>
</template>

<script>

export default {
  props: ["adyenIntegration", "value"],
  data() {
    return {
      getStarted: false,
      currentTab: this.value,
    };
  },
  methods: {

  },
  computed: {

  },
  watch: {
    currentTab() {
      this.value = this.currentTab;
      this.$emit("input", this.value);
    }
  },
  mounted() {
  },
  filters: {
  },
  components: {
  }
};
</script>




<style>
.card-label {
  letter-spacing: 1px;
  font-weight: bold;
  position: absolute;
  top: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
</style>
