<template>
  <div v-if="$can('manage payment settings')">
    <busy :visible="loading" />
    <div v-if="!loading">
      <div v-if="isStripeUser && !getStarted">
        <div class="card alert-primary mb-3">
          <div class="card-label bg-primary text-white ms-3">
            NEW
          </div>
          <div class="card-body fw-bold">
            <div class="text-center mb-3">
              <img
                  src="@/assets/images/brand/navy-gold-med.png"
                  alt="Swandoola"
                  width="64"
              />
            </div>
            <h5 class="text-center mb-3">
              Introducing Swandoola Payments - integrated payment processing with low fees and multi-currency support!
            </h5>
            <button class="btn btn-primary btn-lg d-block mx-auto" @click="getStarted=true">
              <i class="far fa-credit-card me-1"></i>
              Get Started with Swandoola Payments
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="d-flex mb-2">
          <h5 class="mb-auto text-primary fw-bold">
            Swandoola Payments
          </h5>
        </div>
        <div v-if="!adyenIntegration.id && !getStarted">
          <div class="card mt-3 mb-4 bg-light shadow-sm">
            <div class="card-body">
              <div class="row mt-1">
                <div class="col">
                  <div class="bg-info text-white text-center d-flex mx-auto" style="border-radius: 50%; height:175px; width: 175px">
                    <i class="far fa-lock fa-5x my-auto mx-auto"></i>
                  </div>
                  <h4 class="text-center mt-4">Secure Card Payments</h4>
                </div>
                <div class="col">
                  <div class="bg-info text-white text-center d-flex mx-auto" style="border-radius: 50%; height:175px; width: 175px">
                    <i class="far fa-sack-dollar fa-5x my-auto mx-auto"></i>
                  </div>
                  <h4 class="text-center mt-4">Daily Payouts</h4>
                </div>
                <div class="col">
                  <div class="bg-info text-white text-center d-flex mx-auto" style="border-radius: 50%; height:175px; width: 175px">
                    <i class="far fa-chart-line fa-5x my-auto mx-auto"></i>
                  </div>
                  <h4 class="text-center mt-4">Integrated Reports</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-auto mx-auto text-center">
              <button class="btn btn-primary btn-lg d-block mb-3" @click="getStarted=true">
                <i class="far fa-credit-card me-1"></i>
                Get Started with Swandoola Payments
              </button>
              <router-link to="/">Terms & Agreements</router-link>
            </div>
          </div>
        </div>
        <div v-else>

          <div class="row mb-3">
            <div class="col">
              <div v-if="adyenIntegration.setup_status === 'pending'" class="text-center">
                <h4>Application Submitted</h4>
                <p class="mt-1">We will notify you once your application has been processed.</p>
              </div>
              <div v-else>
                <p class="alert alert-primary" v-if="adyenIntegration.payout_schedule_next_payout">
                  <b>
                    <i class="far fa-sack-dollar me-1" />
                    Next Payout:
                  </b>
                  {{ adyenIntegration.payout_schedule_next_payout | formatDate}}
                </p>

                <setup-tab-switcher v-model="currentTab" :adyen-integration="adyenIntegration" />

                <integration-details
                    v-if="currentTab==='integrationDetails'"
                    :adyen-integration="adyenIntegration"
                    @refreshIntegration="fetchAdyenIntegration" />
                <KYC v-if="currentTab==='kyc'"
                     :adyen-integration="adyenIntegration" />
                <payout-schedule v-if="currentTab==='payoutSchedule'"
                                 :adyen-integration="adyenIntegration" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationDetails from "./partials/IntegrationDetails";
import KYC from "@/views/areas/payments/partials/KYC";
import IntegrationStatus from "@/views/areas/payments/partials/IntegrationStatus";
import PayoutSchedule from "@/views/areas/payments/partials/PayoutSchedule";
import PaymentsNav from "@/views/areas/payments/partials/PaymentsNav";
import StripeConnect from "./SetupStripeConnect";
import SetupTabSwitcher from "./partials/SetupTabSwitcher";

export default {
  props: [],
  data() {
    return {
      getStarted: false,
      loading: true,
      adyenIntegration: null,
      currentTab: null,
    };
  },
  methods: {
    fetchAdyenIntegration() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/settings/clinic/fetch-adyen-integration").then(({ data }) => {
        this.adyenIntegration = data;
        if (!this.adyenIntegration || !this.adyenIntegration.account_code) {
          this.currentTab = 'integrationDetails';
        }
        this.loading = false;
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    },
    isStripeUser() {
      if (this.user.clinic.stripe_integration_id) {
        return (!this.adyenIntegration)
      }
      return false;
    }
  },
  watch: {
    getStarted() {
      this.$emit("toggleSetup");
    }
  },
  mounted() {
    this.fetchAdyenIntegration();
  },
  filters: {
    formatDate(date) {
      return moment(date).local().format("LLLL");
    }
  },
  components: {
    SetupTabSwitcher,
    StripeConnect,
    PaymentsNav,
    PayoutSchedule,
    IntegrationStatus,
    KYC,
    IntegrationDetails,
  }
};
</script>




<style>
.card-label {
  letter-spacing: 1px;
  font-weight: bold;
  position: absolute;
  top: 0;
  padding: 0.25rem 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
</style>
