<template>
  <div class="mb-3">
    <div class="card bg-light">
      <div class="card-body">
        <div class="mt-3">
          <p class="alert alert-primary">
            Provide us with basic information about your business.
          </p>


          <div>
            <p v-if="editDetails && !firstTime" class="alert alert-danger">
              Updating your details will re-trigger the identity verification process and could cause delays to payouts.
            </p>
            <form @submit.prevent="saveAccountDetails">
              <div class="row">
                <div class="col-md-6">
                  <label>Legal Entity Type</label>
                  <select v-model="accountDetails.legalEntity"
                          :disabled="!editDetails"
                          class="form-control mb-3"
                          required>
                    <option value="">Select a Legal Entity Type</option>
                    <option value="Individual">Individual</option>
                    <option value="Business">Business</option>
                  </select>
                </div>

                <div class="col-md-6">
                  <label>Currency</label>
                  <select v-model="accountDetails.currency"
                          class="form-control mb-3"
                          required
                          :disabled="!editDetails">
                    <option :value="null" selected disabled>Select a Default Currency</option>
                    <option value="GBP">£ GBP</option>
                    <option value="EUR">€ EUR</option>
                  </select>
                </div>

              </div>

              <div v-if="accountDetails.legalEntity && accountDetails.currency">
                <div v-if="accountDetails.legalEntity === 'Individual'">
                  <div class="row">
                    <div class="col-md-6">
                      <label>First Name</label>
                      <input type="text"
                             class="form-control mb-3"
                             :disabled="!editDetails"
                             v-model="accountDetails.individual.firstName"
                             required
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Last Name</label>
                      <input type="text"
                             class="form-control mb-3"
                             :disabled="!editDetails"
                             v-model="accountDetails.individual.lastName"
                             required
                      />
                    </div>
                  </div>

                  <label>Email Address</label>
                  <input type="email"
                         class="form-control mb-3"
                         :disabled="!editDetails"
                         v-model="accountDetails.individual.email"
                         required />
                </div>

                <div v-if="accountDetails.legalEntity">
                  <h5>Address</h5>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Country</label>
                      <select v-model="accountDetails.address.country"
                              :disabled="!editDetails"
                              class="form-control mb-3"
                              required>
                        <option v-for="(item, key) in countries" :value="key">{{ item }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Property Name / Number</label>
                      <input type="text"
                             class="form-control mb-3"
                             :disabled="!editDetails"
                             v-model="accountDetails.address.houseNumberOrName"
                             required />
                    </div>
                    <div class="col-md-6">
                      <label>Street</label>
                      <input type="text"
                             class="form-control mb-3"
                             :disabled="!editDetails"
                             v-model="accountDetails.address.street"
                             required />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>City</label>
                      <input type="text"
                             class="form-control mb-3"
                             :disabled="!editDetails"
                             v-model="accountDetails.address.city"
                             required />
                    </div>
                    <div class="col-md-6">
                      <label>Postal Code</label>
                      <input type="text"
                             class="form-control mb-3"
                             :disabled="!editDetails"
                             v-model="accountDetails.address.postalCode"
                             required />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Phone</label>
                      <phone-number-input
                          v-model="accountDetails.fullPhoneNumber"
                          default-country-code="GB"
                          :preferred-countries="['GB', 'MT', 'GR', 'ES', 'FR', 'BE', 'DE']"
                          :disabled="!editDetails"
                          required
                      ></phone-number-input>
                    </div>
                  </div>
                </div>

                <div v-if="accountDetails.legalEntity === 'Business'">
                  <div class="row">
                    <div class="col-md-6">
                      <label>Business Legal Name</label>
                      <input type="text"
                             class="form-control mb-3"
                             :disabled="!editDetails"
                             v-model="accountDetails.business.legalBusinessName"
                             required />
                    </div>
                    <div class="col-md-6">
                      <label>Email Address</label>
                      <input type="email"
                             class="form-control mb-3"
                             :disabled="!editDetails"
                             v-model="accountDetails.business.email"
                             required />
                    </div>
                  </div>




                  <label>Shareholders</label>
                  <div v-for="s in accountDetails.business.shareholders"
                       class="card bg-silver shadow mb-3 rounded p-4">
                    <div class="row">
                      <div class="col-md-6">
                        <label>First Name</label>
                        <input type="text"
                               class="form-control mb-2"
                               :disabled="!editDetails"
                               v-model="s.name.firstName"
                               required />
                      </div>
                      <div class="col-md-6">
                        <label>Last Name</label>
                        <input type="text"
                               class="form-control mb-2"
                               :disabled="!editDetails"
                               v-model="s.name.lastName"
                               required />
                      </div>
                    </div>

                    <label>Country</label>
                    <select v-model="s.address.country"
                            :disabled="!editDetails"
                            class="form-control mb-3"
                            required>
                      <option v-for="(item, key) in countries" :value="key">{{ item }}</option>
                    </select>

                    <div class="row">
                      <div class="col text-end">
                        <button class="btn btn-outline-danger"
                                v-if="editDetails && accountDetails.business.shareholders.length > 1"
                                @click.prevent="removeShareholder(s)">
                          Remove Shareholder
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div v-if="editDetails">
                <button class="btn btn-primary float-end"
                        :disabled="saving || !accountDetails.legalEntity || !accountDetails.currency" type="submit">
                  <i v-if="saving" class="far fa-spin fa-spinner me-2"></i>
                  <i v-else class="far fa-save me-2"></i>
                  Save
                </button>
                <button class="btn btn-outline-primary" v-if="accountDetails.legalEntity === 'Business'"
                        :disabled="!editDetails" @click.prevent="addShareholder">
                  <i class="far fa-plus me-1"></i>
                  Add another Shareholder
                </button>
                <button class="btn btn-outline-danger float-end me-2" @click.prevent="cancelEdit" :disabled="saving">
                  <i class="far fa-times me-2"></i>
                  Cancel
                </button>
              </div>
              <div v-else>
                <button class="btn btn-outline-primary float-end" :disabled="saving" @click.prevent="editDetails=true">
                  <i class="far fa-pencil me-2"></i>
                  Edit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  props: ["adyenIntegration"],
  data() {
    return {
      saving: false,
      editDetails: this.adyenIntegration ? false : true,
      firstTime: this.adyenIntegration ? false : true,
      accountDetails: this.getDefaultAccountDetails(),
    };
  },
  methods: {
    getDefaultAccountDetails() {
      if (this.adyenIntegration){
        return {
          accountHolderCode: process.env.VUE_APP_ADYEN_STORE_IDENTIFIER + "_" + this.$store.getters['auth/user'].clinic_id,
          legalEntity: this.adyenIntegration.integration_details.legal_entity_type,
          currency: this.adyenIntegration.primary_currency,
          individual: {
            firstName: this.adyenIntegration.integration_details.individual_first_name,
            lastName: this.adyenIntegration.integration_details.individual_last_name,
            email: this.adyenIntegration.integration_details.email,
          },
          business: {
            legalBusinessName: this.adyenIntegration.integration_details.business_legal_name,
            email: this.adyenIntegration.integration_details.email,
            shareholders: JSON.parse(this.adyenIntegration.integration_details.business_shareholders) ?? this.getDefaultShareholder()
          },
          address: {
            country: this.adyenIntegration.integration_details.country,
            city: this.adyenIntegration.integration_details.city,
            houseNumberOrName: this.adyenIntegration.integration_details.house_number_or_name,
            postalCode: this.adyenIntegration.integration_details.postal_code,
            street: this.adyenIntegration.integration_details.street,
          },
          fullPhoneNumber: ""
        };
      } else {
        return {
          accountHolderCode: process.env.VUE_APP_ADYEN_STORE_IDENTIFIER + "_" + this.$store.getters['auth/user'].clinic_id,
          legalEntity: "",
          currency: "",
          individual: {
            firstName: this.$store.getters['auth/user'].first_name,
            lastName: this.$store.getters['auth/user'].last_name,
            email: this.$store.getters['auth/user'].email,
          },
          business: {
            legalBusinessName: this.$store.getters['auth/user'].clinic.name,
            email: this.$store.getters['auth/user'].clinic.email_address,
            shareholders: this.getDefaultShareholder(),
          },
          address: {
            country: "GB",
            city: "",
            houseNumberOrName: "",
            postalCode: "",
            street: ""
          },
          fullPhoneNumber: ""
        };
      }
    },
    getDefaultShareholder() {
      return [
        {
          name: {
            firstName: this.$store.getters['auth/user'].first_name,
            lastName: this.$store.getters['auth/user'].last_name,
            gender: "UNKNOWN"
          },
          address: {
            country: "GB",
          },
        }
      ];
    },
    addShareholder() {
      this.accountDetails.business.shareholders.push(
          {
            name: {
              firstName: "",
              lastName: "",
              gender: "UNKNOWN"
            },
            address: {
              country: "GB",
            }
          }
      )
    },
    removeShareholder(s) {
      this.accountDetails.business.shareholders = this.accountDetails.business.shareholders.filter(item => item !== s);
    },
    saveAccountDetails() {
      this.saving = true;
      if (this.adyenIntegration && this.adyenIntegration.account_code){
        //update
        this.$axios.put(process.env.VUE_APP_API_URL + "/settings/payments/account-details",
            this.accountDetails
        )
            .then(({ data }) => {
              this.finishedSaving(data);
            });
      } else {
        //create
        this.$axios.post(process.env.VUE_APP_API_URL + "/settings/payments/account-details", this.accountDetails)
            .then(({ data }) => {
              const vm = this;
              setTimeout(function() {
                vm.finishedSaving(data);
              }, 5000);
            });
      }
    },
    finishedSaving(data) {
      this.$emit("refreshIntegration");
      this.$EventBus.$emit("alert", data);
      this.saving = false;
      this.editDetails = false;
    },
    cancelEdit() {
      this.$emit("refreshIntegration");
      this.editDetails = false;
    }
  },
  watch: {
    adyenIntegration() {
      this.accountDetails = this.getDefaultAccountDetails();
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    },
    countries() {
      return {
        "AT":"Austria",
        "AU":"Australia",
        "BE":"Belgium",
        "CA":"Canada",
        "HR":"Croatia",
        "CZ":"Czech Republic",
        "EE":"Estonia",
        "FI":"Finland",
        "FR":"France",
        "DE":"Germany",
        "GR":"Greece",
        "HU":"Hungary",
        "IE":"Ireland",
        "IT":"Italy",
        "LV":"Latvia",
        "LT":"Lithuania",
        "LU":"Luxembourg",
        "NL":"Netherlands",
        "PL":"Poland",
        "PT":"Portugal",
        "SK":"Slovakia",
        "SI":"Slovenia",
        "ES":"Spain",
        "CH":"Switzerland",
        "GB":"United Kingdom (including Isle of Man & Jersey)",
        "US":"United States",
      };
    }
  },
  mounted() {
  },
  filters: {
  },
  components: {
  }
};
</script>
