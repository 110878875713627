<template>
  <div class="card mb-3">
    <div class="card-body">
      <div class="row" v-if="!busy">
        <div class="col text-center">
          <p
              v-if="stripe_integration.onboarding_status === 'active'"
              class="mb-3 text-primary"
          >
            <i class="fa fa-check-circle text-primary me-2"></i>You have
            verified your identity.
          </p>

          <p
              class="mb-3"
              v-if="stripe_integration.onboarding_status === 'pending'"
          >
            To work with Swandoola, please complete a quick verification
            process with Stripe, a trusted and secure payments platform that
            handles billions of transactions around the world.
          </p>

          <p
              v-if="tryUpdate && stripe_integration.onboarding_status === 'active'"
              class="mb-3 alert alert-danger"
          >
            Warning: Updating your identity information will re-trigger the
            verification process which could cause payouts and transfers to
            be temporarily disabled. Proceed with caution.
          </p>

          <button
              v-if="stripe_integration.onboarding_status === 'active'"
              class="btn btn-outline-primary"
              @click="stripeOnboarding"
          >
            Update Identity Information
          </button>
          <button
              v-if="stripe_integration.onboarding_status === 'pending'"
              class="btn btn-primary"
              @click="stripeOnboarding"
          >
            Verify Your Identity
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["path", "user", "stripe_integration"],
  data() {
    return {
      busy: true,
      bankAccounts: [],
      bankName: null,
      accountNumber: null,
      sortCode: null,
      tryUpdate: false,
    };
  },
  methods: {
    fetchStripeIntegration() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/settings/payments/fetch-stripe-integration",
          {}
        )
        .then(({ data }) => {
          this.stripe_integration = data;
        });
    },
    fetchBankAccounts() {
      return this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/settings/payments/fetch-stripe-bank-accounts",
          {}
        )
        .then(({ data }) => {
          this.bankAccounts = data.data;
        });
    },
    stripeOnboarding() {
      if (this.tryUpdate) {
        this.$axios
            .get(
                process.env.VUE_APP_API_URL +
                "/settings/payments/stripe-onboarding-url",
                {}
            )
            .then(({ data }) => {
              window.location = data;
            });
      } else {
        this.tryUpdate = true;
      }
    },
    updateBankDetails() {
      $(".card-loading").css("display", "flex");
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/payments/update-bank-account",
          {
            bank_name: this.bankName,
            sort_code: this.sortCode,
            account_number: this.accountNumber,
          }
        )
        .then(({ data }) => {
          this.sendAlert(data.message);
          this.fetchStripeIntegration();
          setTimeout(() => {
            this.$emit("updated");
          }, 2000);
          $(".card-loading").css("display", "none");
        })
        .catch((error) => {
          $(".card-loading").css("display", "none");
          if (error.response.status == 422) {
            this.errors = error.response.data.errors;
          }
          if (error.response.status == 500) {
            this.sendAlert(error.response.data.message);
          }
        });
    },
    sendAlert(msg) {
      if (msg.length > 50) {
        var multi = true;
      } else {
        var multi = false;
      }
      this.$EventBus.$emit("alert", {
        message: msg,
        actionText: null,
        actionHandler: null,
        timeout: 5000,
        multiline: multi,
      });
    },
  },
  computed: {
    hasBankAccount() {
      return this.bankAccounts && this.bankAccounts.length > 0;
    },
  },
  mounted() {
    this.fetchBankAccounts().then(() => {
      this.busy = false;
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLLL");
    },
  },
};
</script>

<style>
</style>
